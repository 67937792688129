import { getUfcShopComposedApi, i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button, Checkbox, message, Modal, Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import cn from 'classnames';
import { compact, isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getUseShopQuoteStoreByCode,
  loadOrderInShopEditorStore,
  ShopOrderQuoteStoreModifiers,
} from '@/stores';

import { useAppNavigate } from '../../../../../shared';
import styles from './index.module.less';

export interface ShopQuoteCheckoutContinueToPayBtnProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
}

export const ShopQuoteCheckoutContinueToPayBtn = ({
  className,
  style,

  orderCode,
}: ShopQuoteCheckoutContinueToPayBtnProps) => {
  const navigate = useNavigate();
  const [loading, toggleLoading] = useState<boolean>(false);

  const nav = useAppNavigate();

  const useShopQuoteStore = getUseShopQuoteStoreByCode(orderCode);

  const {
    shopOrder,
    allowEdit,
    shopOrderPrice,
    isAgreeTermOfService,
    shopOrderQuotation,
  } = useShopQuoteStore(s => ({
    allowEdit: s.allowEdit,
    shopOrder: s.shopOrder,
    shopOrderPrice: s.shopOrderPrice,
    isAgreeTermOfService: s.isAgreeTermOfService,
    shopOrderQuotation: s.shopOrderQuotation,
  }));

  const isRfq = !shopOrderPrice ? true : shopOrderPrice.status !== 'OK';

  const onSubmit = async () => {
    if (!isAgreeTermOfService) {
      return Modal.info({
        width: 500,
        maskClosable: true,
        bodyStyle: { padding: '20px 24px' },
        title: i18nFormat('Please review and agree to the terms of service.'),
        content: (
          <Checkbox
            defaultChecked={isAgreeTermOfService}
            className={styles.agreeTermOfService}
            onChange={e =>
              useShopQuoteStore.setState({
                isAgreeTermOfService: e.target.checked,
              })
            }
          >
            {i18nFormat("I agree to Unionfab's")}&nbsp;
            <a onClick={() => nav.navigateToPdfPageNewTab('csa')}>
              {i18nFormat('Customer Service Agreement')}
            </a>
          </Checkbox>
        ),
      });
    }

    try {
      toggleLoading(true);

      const hasAddress = S.get(shopOrder, s => s.delivery.items, []).length > 0;

      if (!hasAddress) {
        return message.warning(i18nFormat('Please select a shipping address'));
      }

      const expressId = S.get(
        shopOrder,
        s => s.requirements.customerSelections.delivery.expressId,
      );

      if (!expressId) {
        return message.warning(i18nFormat('Please select a shipping method'));
      }

      /** 提交之前先保存一下订单方防止订单备注未更新 */
      await ShopOrderQuoteStoreModifiers.saveOrderToServer(orderCode);

      let resp = true;

      const billingInfo = useShopQuoteStore.getState().billingInfo;

      /** 判断是否需要更新账单数据 */
      if (S.isValid(billingInfo)) {
        const isRequired = useShopQuoteStore.getState().isBillingInfoRequired;

        /** 表单展开则为必填 */
        if (typeof isRequired == 'boolean' && isRequired) {
          if (compact(Object.values(billingInfo)).length !== 6) {
            useShopQuoteStore.setState({ isBillingInfoValid: false });

            return message.error(i18nFormat('Incomplete form.'));
          }
        }

        const remoteInfo = await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrderBillingInfo(
          orderCode,
          false,
        );

        if (!isEqual(billingInfo, remoteInfo)) {
          resp = await getUfcShopComposedApi().shopOrderUpdateApi.updateShopOrderBillingInfo(
            orderCode,
            billingInfo,
          );
        }
      }

      if (
        shopOrder.status == 'WAIT_SUBMIT' ||
        (shopOrder.status == 'WAIT_CUSTOM_CONFIRM' && isRfq)
      ) {
        // 判断当前订单状态，如果为 WAIT_SUBMIT 则切换到 WAIT_REVIEW
        resp = await getUfcShopComposedApi().shopOrderUpdateApi.submitShopOrderByCode(
          orderCode,
        );

        if (!resp) {
          toggleLoading(false);
          return message.error(i18nFormat('Order submission failed'));
        }
      }

      if (resp && !isRfq) {
        // 从服务端抓取数据
        const newOrder = await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrderByCode(
          orderCode,
        );
        // 判断当前是否需要自动确认
        if (newOrder.status === 'WAIT_REVIEW') {
          resp = await getUfcShopComposedApi().shopOrderUpdateApi.confirmShopOrderByCode(
            orderCode,
          );
        }

        /** 更新订单信息 */
        await loadOrderInShopEditorStore(orderCode);

        await S.sleep(500);

        if (resp) {
          toggleLoading(false);
          // 跳转支付页面
          navigate(`/new_shop_quote/${orderCode}/payment`);
        }
      }

      if (resp && isRfq) {
        toggleLoading(false);
        navigate(`/new_shop_quote/${orderCode}/checkout-success`);
      }
    } catch (e) {
      console.log('>>>ShopQuoteCheckoutContinueToPayBtn>>>submit error', e);
    } finally {
      toggleLoading(false);
    }
  };

  /** 渲染还在等待人工报价的 */
  const rfqDetailReason = useMemo(() => {
    let reasonStr = '';

    const [reason] = S.get(shopOrderQuotation, q => q.production.reason, []);

    if (isRfq) {
      reasonStr = S.getShopOrderQuotationRfqDetailReason(null);
    }

    if (reason && reason.type) {
      reasonStr = S.getShopOrderQuotationRfqDetailReason(reason.type);
    }

    if (S.get(shopOrderQuotation, q => q.production.status) == 'COMPUTING') {
      reasonStr = S.getShopOrderQuotationRfqDetailReason('computing');
    }

    if (reasonStr == '') return <></>;

    return <div className={styles.rfqDesc}>{i18nFormat(reasonStr)}</div>;
  }, [isRfq, shopOrderQuotation]);

  /** 订单提交后且为 RFQ 的订单等待人工报价不允许提交 */
  const disabled = !allowEdit && isRfq && shopOrder.status == 'WAIT_REVIEW';

  const checkBtnTxt = useMemo(() => {
    if (!allowEdit && isRfq && shopOrder && shopOrder.status == 'WAIT_REVIEW') {
      return 'Wait for Checkout';
    }

    return isRfq ? 'Request a Manual Quote' : 'Continue to Pay';
  }, [isRfq, shopOrder, allowEdit]);

  const submitButton = useMemo(() => {
    const btnProps: ButtonProps = {
      loading,
      disabled,
      block: true,
      size: 'large',
      type: 'primary',
      onClick: onSubmit,
      children: i18nFormat(checkBtnTxt),
      className: cn(
        isRfq && 'animate__animated animate__flash animate__repeat-2',
      ),
    };

    if (!S.isValidArray(shopOrder.items || [])) {
      return (
        <Tooltip title={i18nFormat('Please upload the model file first')}>
          <Button {...btnProps} disabled={true} />
        </Tooltip>
      );
    }

    return <Button {...btnProps}>{i18nFormat(checkBtnTxt)}</Button>;
  }, [disabled, shopOrder, loading, checkBtnTxt, isAgreeTermOfService]);

  return (
    <div
      id="ShopQuoteCheckoutContinueToPayBtn"
      className={cn(className, styles.container)}
      style={style}
    >
      {submitButton}
      {rfqDetailReason}
    </div>
  );
};

ShopQuoteCheckoutContinueToPayBtn.displayName =
  'ShopQuoteCheckoutContinueToPayBtn';
