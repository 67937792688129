/** 简单地对 URL 进行解析 */
export function parseUrl(url: string) {
  const match = (url || '').match(
    /^(http|https|ftp)?(?:[\:\/]*)([a-z0-9\.-]*)(?:\:([0-9]+))?(\/[^?#]*)?(?:\?([^#]*))?(?:#(.*))?$/i,
  );
  const ret: any = new Object();

  if (!match) {
    return {};
  }

  ret.protocol = '';
  ret.host = match[2];
  ret.port = '';
  ret.path = '';
  ret.query = '';
  ret.fragment = '';

  if (match[1]) {
    ret.protocol = match[1];
  }

  if (match[3]) {
    ret.port = match[3];
  }

  if (match[4]) {
    ret.path = match[4];
  }

  if (match[5]) {
    ret.query = match[5];
  }

  if (match[6]) {
    ret.fragment = match[6];
  }

  return ret;
}

/** 获取当前 Url 中的参数 */
export function getUrlParamWithRegex(name: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

// https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export function getUrlParameterByName(name, _url?: string): string | undefined {
  const url =
    _url || (typeof window === 'undefined' ? '' : window.location.href);

  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/** 判断是否为本地域名 */
export function isLocalHost(str = '') {
  return (
    str.includes('localhost') ||
    str.includes('127.0.0.1') ||
    str.includes('0.0.0.0')
  );
}
