import {
  getUrlParameterByName,
  UfcShopTokenUtils,
} from '@unionfab/ufc-shop-commons';
import { useEffect } from 'react';

import { useAppNavigate } from './useAppNavigate';

export const OAuthRecvResponsePath = 'auth';

/** 外部登录之后返回的 Token 接收的组件 */
export const OAuthRecvResponseHandler = () => {
  const nav = useAppNavigate();
  const token = getUrlParameterByName('token');
  const idToken = getUrlParameterByName('idToken');
  const redirectTo = getUrlParameterByName('redirectTo');
  // const requestAuth = getUrlParameterByName('request') === 'true';
  useEffect(() => {
    if (token && idToken) {
      // 接收到 token
      UfcShopTokenUtils.setIdToken(idToken);
      UfcShopTokenUtils.setToken(token);

      if (redirectTo && redirectTo !== window.location.href) {
        window.open(redirectTo, '_self');
      } else {
        nav.navToNewQuote();
      }
    } else {
      /** useAuthToken 中会处理是否跳转至登录页 */
      // if (!hasAuthenticated || isDemoUser) {
      //   if (requestAuth) {
      //     openLoginPage();
      //     return;
      //   }
      // }
      // nav.navToNewQuote();
    }
  }, [token, redirectTo]);
  return <></>;
};

function createAuthRecvUrl() {
  const { protocol, host, pathname } = window.location;
  const recvUrl = `${protocol}//${host}${pathname}#/${OAuthRecvResponsePath}?token=__TOKEN__&idToken=__IDTOKEN__&redirectTo=${encodeURIComponent(
    window.location.href,
  )}`;
  return recvUrl;
}

export function openLoginPage() {
  // 不可访问，意味着需要登录才能访问
  let authUrl: string;

  try {
    authUrl =
      import.meta.env.VITE_AUTH_URL || 'https://www.unionfab.com/auth/login';
  } catch (e) {
    authUrl = 'https://www.unionfab.com/auth/login';
  }

  const back = createAuthRecvUrl();

  window.open(
    `${authUrl}?redirectTo=${encodeURIComponent(back)}&redirectTarget=_self`,
    '_self',
  );
}
