import { useInterval } from 'ahooks';
import dayjs from 'dayjs';
import { default as jwtDecode } from 'jwt-decode';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { get } from '../../schema';
import {
  useGlobalStore,
  useInquiryCouponStore,
  useReceiveAddressMgtStore,
} from '../../stores';
import { AccessTokenData, TOKEN_KEY, UfcShopTokenUtils } from '../env';

const ALLOW_DEMO_PATH = [
  '/anonymous_new_shop_quote',
  '/quality-inspection',
  '/cancel-subscribe-email',
];

// depends on react-router.
export const useAuthToken = () => {
  const { pathname } = useLocation();

  const { loadInquiryCoupons } = useInquiryCouponStore(o => ({
    loadInquiryCoupons: o.loadInquiryCoupons,
  }));
  const { loadAddressList } = useReceiveAddressMgtStore(o => ({
    loadAddressList: o.loadAddressList,
  }));

  const {
    isDemoUser,
    hasAuthenticated,
    loadUser,
    clearToken,
    refreshAccessToken,
    loginOrdinary,
  } = useGlobalStore(g => ({
    loadUser: g.loadUser,
    clearToken: g.clearToken,
    isDemoUser: g.isDemoUser,
    hasAuthenticated: g.hasAuthenticated,
    refreshAccessToken: g.refreshAccessToken,
    loginOrdinary: g.loginOrdinary,
  }));

  useEffect(() => {
    /** 调用 UfcShopTokenUtils 中的 setToken 方法会触发 setTokenEvent 事件，这边会接收到最新的 token  */
    window.addEventListener('setTokenEvent', (e: StorageEvent) => {
      const eventToken = e[TOKEN_KEY];

      if (eventToken && UfcShopTokenUtils.isTokenValid(eventToken)) {
        UfcShopTokenUtils.refreshToken();
        loadUser(true);
        loadAddressList();
        loadInquiryCoupons();
      }
    });

    return () => {
      window.removeEventListener('setTokenEvent', () => {});
    };
  }, []);

  /** 判断 token 是否有效 是否为 demo token */
  useEffect(() => {
    /** 页面不允许 demo 用户访问 */
    if (ALLOW_DEMO_PATH.every(path => !pathname.startsWith(path))) {
      /** idToken 无效或者为 demo 用户直接跳转登录页 */

      if (
        !UfcShopTokenUtils.getIdToken(true) ||
        !hasAuthenticated ||
        (hasAuthenticated && isDemoUser)
      ) {
        openLoginPage();
      }
    } else {
      /** 页面允许 demo 用户访问 */
      /** LoadableContainer 中已有刷新 demo token 逻辑 */
    }
  }, [hasAuthenticated, isDemoUser]);

  useInterval(
    () => {
      /** 匿名登录页面触发登录后刷新数据 */
      const user = UfcShopTokenUtils.decodeAccessToken(
        UfcShopTokenUtils.getAccessToken(true),
      );

      if (!!user && isNil(get(user, u => u.anonymousSessionId))) {
        loadUser(true);
      }
    },
    hasAuthenticated && isDemoUser ? 600 : undefined,
    { immediate: false },
  );

  /** 监听 accessToken 是否过期 */
  useInterval(() => {
    /** 有 idToken */
    if (UfcShopTokenUtils.getIdToken(true)) {
      const accessToken = UfcShopTokenUtils.getAccessToken(true);

      /** 提前十分钟刷新 accessToken */
      if (!accessToken || isJwtTokenExpired(accessToken, 10)) {
        console.warn('wait refresh accessToken');
        /** 首先判断 idToken 是否过期 */
        if (!isJwtTokenExpired(UfcShopTokenUtils.getIdToken(true), 10)) {
          refreshAccessToken();
        } else {
          /** idToken 过期直接清空token信息 */
          clearToken();
        }
      }
    } else {
      /** 重置用户信息并更新 hasAuthenticated 相关信息触发页面跳转逻辑或 demo token 刷新逻辑 */
      if (hasAuthenticated && !isDemoUser) {
        useGlobalStore.setState({ hasAuthenticated: false, isDemoUser: false });
      }

      /** 判断 demo token 是否过期  */
      if (hasAuthenticated && isDemoUser) {
        const demoAccessToken = UfcShopTokenUtils.getAccessToken(true);

        /** 提前十分钟刷新 demoAccessToken */
        if (!demoAccessToken || isJwtTokenExpired(demoAccessToken, 10)) {
          console.warn('wait refresh accessToken');
          /** 这里直接重新获取 demoAccessToken */
          loginOrdinary();
        }
      }
    }
  }, 3000);
};

const isJwtTokenExpired = (
  token: string | undefined,
  lead = 0,
  leadType: dayjs.ManipulateType = 'm',
) => {
  if (!token || token == '') return true;

  const { exp } = jwtDecode(token) as AccessTokenData;

  if (!exp || typeof exp !== 'number') return true;

  // 这里引入提前量，以提前进行 Token 更新
  if (dayjs.unix(exp).isAfter(dayjs().add(lead, leadType))) return false;

  return true;
};

function createAuthRecvUrl() {
  const { protocol, host, pathname } = window.location;
  const recvUrl = `${protocol}//${host}${pathname}#/auth?token=__TOKEN__&idToken=__IDTOKEN__&redirectTo=${encodeURIComponent(
    window.location.href,
  )}`;
  return recvUrl;
}

function openLoginPage() {
  // 不可访问，意味着需要登录才能访问
  let authUrl: string;

  try {
    authUrl =
      import.meta.env.VITE_AUTH_URL || 'https://www.unionfab.com/auth/login';
  } catch (e) {
    authUrl = 'https://www.unionfab.com/auth/login';
  }

  const back = createAuthRecvUrl();

  window.open(
    `${authUrl}?redirectTo=${encodeURIComponent(back)}&redirectTarget=_self`,
    '_self',
  );
}
